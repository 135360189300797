import React from 'react'

function Suscribe() {
  return (
    <section class="bg-dark text-center p-4 my-4">
		<div class="container p-3">
			<h3 class="text-white">Bienvenido a OenPark</h3>
		</div>
	</section>
  )
}

export default Suscribe